var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.tag.type == "string"
        ? _c("Input", {
            attrs: {
              name: _vm.tag.name,
              label: _vm.tag.display_name,
              title: _vm.tag.description,
              size: 6,
              value: _vm.value
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tag.type == "oneof"
        ? _c("Select", {
            attrs: {
              name: _vm.tag.name,
              label: _vm.tag.display_name,
              title: _vm.tag.description,
              size: 6,
              options: _vm.oneofOptions,
              value: _vm.value
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tag.type == "boolean"
        ? _c("Select", {
            attrs: {
              name: _vm.tag.name,
              label: _vm.tag.display_name,
              title: _vm.tag.description,
              size: 6,
              options: _vm.booleanOptions,
              value: _vm.value
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tag.type == "date"
        ? _c("Date", {
            attrs: {
              name: _vm.tag.name,
              label: _vm.tag.display_name,
              title: _vm.tag.description,
              size: 6,
              value: _vm.value
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tag.type == "integer" || _vm.tag.type == "float"
        ? _c("Number", {
            attrs: {
              name: _vm.tag.name,
              label: _vm.tag.display_name,
              title: _vm.tag.description,
              size: 6,
              min: _vm.tag.min,
              max: _vm.tag.max,
              step: _vm.tag.step,
              value: _vm.value
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }