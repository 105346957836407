<template>
  <div>
    <Input v-if="tag.type=='string'" :name=tag.name :label=tag.display_name :title=tag.description :size=6 
    :value=value @input='$emit("input", $event)'/>
    <Select v-if="tag.type=='oneof'" :name=tag.name :label=tag.display_name :title=tag.description :size=6 :options=oneofOptions 
    :value=value @input='$emit("input", $event)' />
    <Select v-if="tag.type=='boolean'" :name=tag.name :label=tag.display_name :title=tag.description :size=6 :options=booleanOptions 
    :value=value @input='$emit("input", $event)' />
    <Date v-if="tag.type=='date'" :name=tag.name :label=tag.display_name :title=tag.description :size=6 
    :value=value @input='$emit("input", $event)' />
    <Number v-if="tag.type=='integer' || tag.type=='float'" :name=tag.name :label=tag.display_name :title=tag.description :size=6
     :min=tag.min :max=tag.max :step=tag.step :value=value @input='$emit("input", $event)' 
    />
  </div>
</template>

<script>

import Input from "common/visual/input/Input.vue"
import Select from "common/visual/input/Select.vue"
import TextArea from "common/visual/input/TextArea.vue"
import Date from "common/visual/input/Date.vue"
import Number from "common/visual/input/Number.vue"

export default {
  name: "ClientTagInput",
  
  components: {Input, Select, TextArea, Date, Number},
  
  props: {
    tag: Object,
    value: null,
  },
  
  computed: {
    oneofOptions()
    {
      return this.tag.oneof.split(",").map(el => { return {text: el.trim(), value: el.trim()} })
    },
    booleanOptions()
    {
      return [
        {value: "", text: "Нет"},
        {value: 1, text: "Да"},
      ]
    },
  },
}
</script>
